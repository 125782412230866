// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-about-index-js": () => import("./../../../src/components/About/index.js" /* webpackChunkName: "component---src-components-about-index-js" */),
  "component---src-components-article-detail-index-js": () => import("./../../../src/components/ArticleDetail/index.js" /* webpackChunkName: "component---src-components-article-detail-index-js" */),
  "component---src-components-article-index-js": () => import("./../../../src/components/Article/index.js" /* webpackChunkName: "component---src-components-article-index-js" */),
  "component---src-components-contact-page-index-js": () => import("./../../../src/components/ContactPage/index.js" /* webpackChunkName: "component---src-components-contact-page-index-js" */),
  "component---src-components-hair-quiz-index-js": () => import("./../../../src/components/HairQuiz/index.js" /* webpackChunkName: "component---src-components-hair-quiz-index-js" */),
  "component---src-components-hair-quiz-result-index-js": () => import("./../../../src/components/HairQuizResult/index.js" /* webpackChunkName: "component---src-components-hair-quiz-result-index-js" */),
  "component---src-components-hair-quiz-start-index-js": () => import("./../../../src/components/HairQuizStart/index.js" /* webpackChunkName: "component---src-components-hair-quiz-start-index-js" */),
  "component---src-components-hairpedia-index-js": () => import("./../../../src/components/Hairpedia/index.js" /* webpackChunkName: "component---src-components-hairpedia-index-js" */),
  "component---src-components-home-index-js": () => import("./../../../src/components/Home/index.js" /* webpackChunkName: "component---src-components-home-index-js" */),
  "component---src-components-privacy-index-js": () => import("./../../../src/components/Privacy/index.js" /* webpackChunkName: "component---src-components-privacy-index-js" */),
  "component---src-components-product-index-js": () => import("./../../../src/components/Product/index.js" /* webpackChunkName: "component---src-components-product-index-js" */),
  "component---src-components-product-subcat-index-js": () => import("./../../../src/components/ProductSubcat/index.js" /* webpackChunkName: "component---src-components-product-subcat-index-js" */),
  "component---src-components-search-result-index-js": () => import("./../../../src/components/SearchResult/index.js" /* webpackChunkName: "component---src-components-search-result-index-js" */),
  "component---src-components-social-media-index-js": () => import("./../../../src/components/SocialMedia/index.js" /* webpackChunkName: "component---src-components-social-media-index-js" */),
  "component---src-components-store-location-index-js": () => import("./../../../src/components/StoreLocation/index.js" /* webpackChunkName: "component---src-components-store-location-index-js" */),
  "component---src-components-tn-c-index-js": () => import("./../../../src/components/TnC/index.js" /* webpackChunkName: "component---src-components-tn-c-index-js" */),
  "component---src-components-videos-index-js": () => import("./../../../src/components/Videos/index.js" /* webpackChunkName: "component---src-components-videos-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-not-found-js": () => import("./../../../src/pages/notFound.js" /* webpackChunkName: "component---src-pages-not-found-js" */)
}

